<template>
  <div>
    <v-card elevation="2" tile>
      <v-card-title>Layout: {{ layout.name }} </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <br />
        <table class="table table-striped">
          <tbody>
            <tr>
              <td><strong>Id</strong></td>
              <td>{{ layout.id }}</td>
            </tr>
            <tr>
              <td><strong>Descripción</strong></td>
              <td>{{ layout.descripcion }}</td>
            </tr>
            <tr>
              <td><strong>Grupo Tema</strong></td>
              <td>{{ layout.tema?layout.tema.valor_texto:'' }}</td>
            </tr>
            <tr>
              <td><strong>Tipo de Carga</strong></td>
              <td>{{ layout.tipoCarga }}</td>
            </tr>
            <tr>
              <td><strong>Versión</strong></td>
              <td>{{ layout.version }}</td>
            </tr>
            <tr>
              <td><strong>Delimitador Campo</strong></td>
              <td>{{ layout.delimitadorCampo }}</td>
            </tr>
            <tr>
              <td><strong>Delimitador Texto</strong></td>
              <td>{{ layout.delimitadorTexto }}</td>
            </tr>
            <tr>
              <td><strong>Formato Fecha</strong></td>
              <td>{{ layout.formatoFecha }}</td>
            </tr>
            <tr>
              <td><strong>% Errores Permitidos</strong></td>
              <td>{{ layout.erroresPermitidos }}</td>
            </tr>
            <tr>
              <td><strong>Validar Llave Primaria</strong></td>
              <td>{{ layout.validarLlavePrimaria }}</td>
            </tr>
            <tr>
              <td><strong>Permitir Duplicados</strong></td>
              <td>{{ layout.permitirDuplicados }}</td>
            </tr>
            <tr>
              <td><strong>Activo</strong></td>
              <td>{{ layout.activo }}</td>
            </tr>
          </tbody>
        </table>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <CButton color="secondary" @click="goBack">Volver</CButton>&nbsp;
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Layout",
  data() {
    return {
      layout: {
        id: "",
        name: "",
        email: "",
      },
    };
  },
  computed: {
    ...mapState(["permissions"]),
    fields() {
      return [
        { key: "key", label: this.layout.name, _style: "width:150px" },
        { key: "value", label: this.layout.email },
      ];
    },
  },
  mounted() {
    if (!this.permissions.includes("Definición de layouts"))
      this.$router.push("/dashboard");
    // Verificando que exista datos_layout
    if (localStorage.getItem("datos_layout")) {
      this.layout = JSON.parse(localStorage.getItem("datos_layout"));
    } else {
      // cargarPagina = false
      // this.gestionOpciones()
    }
  },
  methods: {
    goBack() {
      this.$router.push({ path: "/cargue-archivos/layouts" });
    },
  },
};
</script>
